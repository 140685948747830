import { sortTree } from "./utils/helpers";

export function normalizeArrayForTree(array) {
  return array.map(({ id, basePath, children }) => {
    const [{ frontmatter, fields }] = children;
    const directoryName = fields.slug.split("/").reverse().filter(Boolean)[0];

    return {
      id,
      type: basePath.split("/")[0],
      label: frontmatter.title || directoryName,
      path: fields.slug,
      weight: frontmatter.weight || 0,
    };
  });
}

export function pathsToTree(
  array,
  { pathPrefix = "", pathReplace = "", stripRoot = false, debug = false } = {}
) {
  const pathPrefixCount = pathPrefix.split("/").filter(Boolean).length;

  // normalize array data for tree
  array = normalizeArrayForTree(array);

  // sort array by path length
  array.sort(
    (a, b) =>
      a.path.split("/").filter(Boolean).length -
      b.path.split("/").filter(Boolean).length
  );

  let tree = [];
  let branch = { tree };

  if (debug) {
    console.group("PATHS TO TREE");
    console.groupCollapsed(`LOOP NODES`);
  }

  // assign children to parent by path part
  array.forEach((node, index) => {
    const path = node.path.replace(pathReplace, "");
    const pathParts = path.split("/").filter(Boolean);
    pathParts.splice(0, pathPrefixCount);

    if (debug) {
      console.groupCollapsed(`LOOP NODE: ${node.label.toUpperCase()},`, index);
      console.log("Path Arr:", pathParts);
    }

    pathParts.reduce((acc, key, i) => {
      if (!acc[key]) {
        acc[key] = { id: node.id, tree: [] };
        acc.tree.push({
          key,
          ...array[index],
          slug: node.path, // TODO: Change references to path
          parentId: acc?.id || null,
          children: acc[key].tree,
        });
      }

      if (debug) {
        console.groupCollapsed(`REDUCE PATH PART: ${key.toUpperCase()},`, i);
        console.log("ID:", node.id);
        console.log("Parent ID:", acc[key].id);
        // console.log("Branch:", JSON.parse(JSON.stringify(acc)));
        // console.log("Acc[key]:", JSON.parse(JSON.stringify(acc[key])));
        console.groupEnd();
      }

      return acc[key];
    }, branch);

    if (debug) {
      console.groupEnd();
    }
  });

  // sort tree by alphabetical order and weight
  sortTree(tree);

  // remove the root node
  if (stripRoot) {
    tree = tree[0].children;
  }

  if (debug) {
    console.groupEnd();
    console.group("RESULTS");
    console.log(JSON.stringify(tree, null, 2));
    console.groupEnd();
    console.groupEnd();
  }

  return tree;
}
