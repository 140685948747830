import React from "react";
import config from "../../config";
import { graphql, navigate } from "gatsby";
import { Link } from "@reach/router";
import { isEmpty } from "lodash";
import parse from "html-react-parser";
import { Box, Heading, Text, Select } from "@chakra-ui/react";
import useUser from "../lib/auth";
import SidebarLayout from "../layouts/SidebarLayout";
import ResourceDownload from "../components/ResourceDownload";
import TreeNav from "../components/TreeNav";
import TableOfContents from "../components/TableOfContents";
import slugify from "slugify";
import { pathsToTree } from "../lib/pathsToTree";
import { toTitleCase, getNestedNodeObjPath } from "../lib/utils/helpers";

export const documentationQuery = graphql`
  query DocumentationQuery($id: String!) {
    document: file(id: { eq: $id }) {
      name
      basePath: sourceInstanceName
      relativePath
      relativeDirectory
      gitRemote {
        full_name
        ref
      }
      childMarkdownRemark {
        html
        htmlAst
        headings {
          depth
          value
        }
        frontmatter {
          id
          key
          slug
          title
          subtitle
          weight
          resources {
            resourceKey
            pdf
            pdfLink
            csv
            csvLink
            zip
            zipLink
          }
        }
      }
    }
    allResourceMappingJson {
      nodes {
        resourceKey
        pdfTitle
        pdfPath
        csvTitle
        csvPath
        zipTitle
        zipPath
      }
    }
  }
`;

function VersionSelect({ versions, version, type, href }) {
  const hasMultipleVersions = versions.length >= 2;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      bgColor="blackAlpha.50"
      borderWidth={1}
      borderRadius={5}
      overflow="hidden"
    >
      <Text
        fontSize="md"
        fontWeight="900"
        flex={1}
        m={0}
        px={4}
        py={2}
        borderRightWidth={1}
      >
        <Box as={Link} to={href} display="block">
          {type && toTitleCase(type.replace("-", " ").replace("_", " "))}
        </Box>
      </Text>
      {version && (
        <Box display="flex" alignItems="center" bgColor="white">
          <Select
            isDisabled={!hasMultipleVersions}
            opacity="1 !important"
            mr={!hasMultipleVersions ? "-16px" : null}
            iconColor={!hasMultipleVersions ? "white" : null}
            variant="ghost"
            w="auto"
            borderRadius={0}
            cursor={hasMultipleVersions ? "pointer" : null}
            value={version}
            onChange={({ target }) => {
              const path =
                target.value === versions[0]
                  ? `/${type}/`
                  : `/${type}/${target.value}/`;
              navigate(`${config.documentation.pathPrefix}${path}`);
            }}
          >
            {versions.map((version) => (
              <option key={version} value={version}>
                {version}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
}

export default function Template({ data, pageContext }) {
  const { loading, isAuthenticated } = useUser({ required: true });
  if (loading || !isAuthenticated) return null;

  const { html } = data?.document?.childMarkdownRemark || {};

  return (
    <Box className="content">
      {!html && <Heading as="h1">Documentation</Heading>}
      {html && parse(html)}
      {/* {frontmatter && headings && (
        <>
          <Box my={5}>
            <Heading as="h3" fontSize="lg">
              Frontmatter
            </Heading>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              <Code w="full" p={5} borderRadius={5}>
                {JSON.stringify(frontmatter, null, 2)}
              </Code>
            </pre>
          </Box>
          <Box my={5}>
            <Heading as="h3" fontSize="lg">
              Headings
            </Heading>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              <Code w="full" p={5} borderRadius={5}>
                {JSON.stringify(headings, null, 2)}
              </Code>
            </pre>
          </Box>
        </>
      )} */}
    </Box>
  );
}

Template.getLayout = (page, height) => {
  const { frontmatter, headings } =
    page.props.data?.document?.childMarkdownRemark || {};
  const resourceMap = page.props.data?.allResourceMappingJson.nodes.map(
    (node) => node
  );

  const { versions, version, documents } = page.props.pageContext;

  const documentationTree = pathsToTree(documents, {
    pathPrefix: config.documentation.pathPrefix,
    pathReplace: `${version}/`,
    // stripRoot: true,
    // debug: true,
  });

  const tableOfContents =
    headings?.map(({ value, depth }) => ({
      label: value,
      slug: `#${slugify(value, { lower: true })}`,
      depth,
    })) || [];

  const pathname = page.props?.location?.pathname;
  const breadcrumbObjs = getNestedNodeObjPath(
    documentationTree,
    "slug",
    pathname
  );
  const breadcrumbs = breadcrumbObjs.map((crumb) => ({
    label: crumb.label,
    pathname: crumb.slug,
  }));


  // const pageTitle = breadcrumbs[1]?.label
  //   ? `Ontology: ${breadcrumbs[1].label}`
  //   : "Ontology";
  const meta = {
    // description: "",
    // ogTitle: "",
    // ogDescription: "",
    // ogType: "",
  };
  // Added by DB
  const docType = !isEmpty(documentationTree[0]) && documentationTree[0].type;

  return (
    <SidebarLayout
      {...page.props}
      // TODO: check if this works
      pageTitle={docType.replace("-", " ").replace("_", " ")}
      meta={meta}
      // backgroundImage={page.props?.data?.heroBg}
      breadcrumbs={[
        {
          pathname: config.documentation.pathPrefix,
          label: "Ontology",
        },
        ...breadcrumbs,
      ]}
      leftSidebar={
        <>
          <VersionSelect
            versions={versions}
            version={version}
            // Changed by DB
            type={docType}
            href={documentationTree[0].path}
          />
          <TreeNav
            data={documentationTree[0].children}
            location={page.props.location}
            // compact={900}
          />
        </>
      }
      // remove this for Raymond
      rightSidebar={
        <Box>
          <Heading as="h3" size="sm" mb={8}>
            On This Page
          </Heading>
          <ResourceDownload
              resources={frontmatter.resources}
              resourceMap={resourceMap}
          />
          <TableOfContents data={tableOfContents} />
        </Box>
      }
    >
      {page}
    </SidebarLayout>
  );
};
